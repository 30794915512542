import React from "react";

function Footer() {
	const currentYear = new Date().getFullYear();

	return (
		<footer className="footer">
      <a class="attribute" href="https://pngtree.com/freebackground/hand-painted-spring-flowers-h5-background_1120642.html?sol=downref&id=bef">free background photos from pngtree.com</a>
	    <p>&copy; {currentYear} Natalie Hall</p>
	  </footer>
  )
}

export default Footer
