import React from "react";
import ScrollAnimation from 'react-animate-on-scroll';

function Contact() {
  return (
    <section id="contact">
      <div className="container">
        <ScrollAnimation animateIn='rubberBand' initiallyVisible={true}>
          <h1><span className="first-letter">C</span><span style={{textDecoration:"underline"}}>ontact</span></h1>
        </ScrollAnimation>
        <div className="contact row">
          <div className="left col">
            <div className="logo">
              <h1 className="logo-text"><span className="logo-char">{'<'}</span><span style={{fontSize:'1px'}}> </span><span className="logo-name">techNatalie</span><span className="logo-char">{'/>'}</span></h1>
            </div>
            <h3><a href="mailto:natalie@technatalie.com"><i className="fa-solid fa-envelope"></i> natalie@technatalie.com</a></h3>
            <div className="social">
              <h3><a href="https://linkedin.com/in/nataliedhall" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin"></i></a></h3>
              <h3><a href="https://github.com/The-Natalie" target="_blank" rel="noopener noreferrer"><i className="fab fa-github"></i></a></h3>
            </div>
            <p>Natalie is currently located in Stuarts Draft, <b>Virginia</b> and is looking for a job that is either <b>remote</b> or within <b>Charlottesville</b>, <b>Lexington</b>, <b>Harrisonburg</b>, and the areas in between.</p>
          </div>
          <div className="right col"></div>
        </div>
        <p>**Resume available upon request**</p>
      </div>
    </section>   
  )
}

export default Contact;